import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';
let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class TrainingCandidateService {
    async createTrainingCandidate(data) {
        try{
            const res = await axios.post(API_URL + 'training/candidate', data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getAllTrainingCandidates() {
        try{
            const res = await axios.get(API_URL + 'training/candidate',
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getTrainingCandidateById(id) {
        try{
            const res = await axios.get(API_URL + 'training/candidate/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async updateTrainingCandidate(id) {
        try{
            const res = await axios.put(API_URL + 'training/candidate/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async deleteTrainingCandidate(id) {
        try{
            const res = await axios.delete(API_URL + 'training/candidate/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
}    
export default new TrainingCandidateService();