import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class TrainingBatchService {
    async createTrainingBatch(body) {
        try{
            console.log('batch create body', body)
            const res = await axios.post(API_URL + 'training/batch', body,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getAllTrainingBatches() {
        try{
            const res = await axios.get(API_URL + 'training/batch',
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getTrainingBatchById(id) {
        try{
            const res = await axios.get(API_URL + 'training/batch/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async updateTrainingBatch(id) {
        try{
            const res = await axios.put(API_URL + 'training/batch/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async deleteTrainingBatch(id) {
        try{
            const res = await axios.delete(API_URL + 'training/batch/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
}    
export default new TrainingBatchService();